import React from 'react';
import style from './recentlylaunched.module.css';
import { useEffect } from 'react';
import axios from 'axios';


function RecentlyLaunched() {

    const [Recentlylaunched, setRecentlylaunched] = React.useState([]);

    const Get_Recentlylaunched = () => {
        axios.get("https://api.brainbinaryinfotech.com/recentlylaunched/", {
            headers: { 'content-type': 'multipart/form-data' }
        })
            .then((res) => {
                setRecentlylaunched(res.data.RECENTLY_LAUNCHED_DATA);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        Get_Recentlylaunched()
    }, [])

    return (
        <div className={style.maindiv}>
            <div className={`${style.custom_set}`}>
                <div data-aos="fade-right">
                    <p className={`${style.custom_color} text-lg text-gray-500 mb-2`}> Recently launched </p>
                </div>
                <div data-aos="fade-right">
                    <h1 className='fw-bold mb-2' style={{ color: "#1a202c" }}>
                        Our beautifully crafted solutions
                    </h1>
                </div>
                <div data-aos="fade-right">
                    <p className={`${style.custom_color}`}>
                        We have helped our clients to realize their dreams of creating a great solutions, go live to market, generate great revenues, acquire thousands of users and get million-dollar valuations.
                    </p>
                </div>
            </div>

            {
                Recentlylaunched.map((el, index) => {
                    return (
                        <div className={`${style.custom_set} row gy-xxl-5 gy-xl-5 gy-lg-5 gy-md-4 gy-sm-3 gy-3`} key={index} data-aos="fade-up">
                            <div className="col-6 col-lg-6 col-md-6 col-lg-6 col-12">
                                <div>
                                    <img
                                        style={{ borderRadius: "12px" }}
                                        className={`${style.custom_img}`}
                                        src={el.image.url}
                                        alt="ERROR 404"
                                    />
                                </div>
                            </div>
                            <div className="col-6 col-lg-6 col-md-6 col-lg-6 col-12 ps-lg-5 ps-md-5 ps-sm-0">
                                <div>
                                    <h3 className="fw-bold mb-2" style={{ color: "#1a202c" }}>{el.title}</h3>
                                </div>
                                <div>
                                    <p className={style.custom_color}>
                                        {el.content}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>

    )
}

export default RecentlyLaunched;