import React, { useEffect } from 'react';
import axios from 'axios';
import style from './OurCustomersLove.module.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RiDoubleQuotesL } from "react-icons/ri";
import { RiDoubleQuotesR } from "react-icons/ri";

function OurCustomersLove() {
    const [CustomerLoveContent, setCustomerLoveContent] = React.useState([]);

    const Get_CustomerLoveContent = () => {
        axios.get("https://api.brainbinaryinfotech.com/ourcustomerslove/", {
            headers: { 'content-type': 'multipart/form-data' }
        })
            .then((res) => {
                setCustomerLoveContent(res.data.CUSTOMER_LOVE_DATA);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    var settings = {
        infinite: true,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };



    useEffect(() => {
        Get_CustomerLoveContent()
    }, [])

    return (
        <div className={`${style.BgChangeBack}`}>
            <div className={`heading headingborder text-center mb-4 mt-5 fs-1`} data-aos="zoom-in" data-aos-offset="100">
                Our Customers Love Us. Here's Why.
            </div>
            <div className={`${style.my_element}`}>
                <Slider {...settings}>
                    {
                        CustomerLoveContent.map((el, index) => {
                            return (
                                <div key={index} className='p-3' data-aos="zoom-in" data-aos-offset="0">
                                    <div className={`card ${style.CardStyleChange} shadow`}>
                                        <div className='px-4 py-4 d-flex align-items-center'>
                                            <div className={`${style.Border_Circle}`}>
                                                <img src={el.image.url} alt="ERROR 404" className={`${style.Border_Circle_Img}`} />
                                            </div>
                                            <div>
                                                <h5 className='mb-0 ms-3 text-white'> {el.name} </h5>
                                            </div>
                                        </div>
                                        <div className="px-4 pb-4">
                                            <p className="card-text text-white" style={{ fontSize: "17px", textAlign: "justify" }}>
                                                <sup className='me-1'><RiDoubleQuotesL className='fs-4' /></sup>
                                                {el.content}
                                                <sup className='ms-1'><RiDoubleQuotesR className='fs-4' /></sup>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>
        </div>
    )
}

export default OurCustomersLove;