import React from 'react';
import StatusCode from './StatusCode.png';

function Error404() {
    return (
        <div>
            <img src={StatusCode} alt="404" className='w-100 vh-100' />
        </div>
    )
}

export default Error404;