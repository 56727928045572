import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';

const SecureRouter = (props) => {

    let [Token, SetToken] = useState("");

    const navigate = useNavigate();

    useEffect(() => {

        let CHEAK_TOKEN = localStorage.getItem("ADMIN_TOKEN")

        if (CHEAK_TOKEN) {
            SetToken(CHEAK_TOKEN)
        }
        else {
            navigate("/administration/login")
        }

    }, [navigate])


    if (!Token) {
        return <span> </span>
    }


    return (
        <>
            {
                props.children
            }
        </>
    )
}

export default SecureRouter;