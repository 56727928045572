import React, { useState } from 'react'
import { useEffect } from 'react';
import axios from 'axios';
import { RiDeleteBin5Line } from "react-icons/ri";
// import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import LoderPageRound from '../Testing/LoderPageRound';
import AOS from 'aos';
import 'aos/dist/aos.css';


function GetQuotes() {

    const [GetQuotes, setGetQuotes] = React.useState([]);
    const [loader, Setloader] = useState(false);

    const Get_Quotes = () => {
        axios.get("https://api.brainbinaryinfotech.com/getquote/")
            .then((res) => {
                setGetQuotes(res.data.GET_QUOTES);
                Setloader(true)
            })
            .catch((err) => {
                console.log(err);
                Setloader(true)
            })
    }

    const Delete_Quotes = (_id) => {
        axios.delete("https://api.brainbinaryinfotech.com/getquote/" + _id)
            .then((res) => {
                // toast.success(res.data.message)
                Get_Quotes()
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const Delete_Confirmation = (_id) => {
        Swal.fire({
            title: "Are you sure ?",
            text: "You won't to delete this Quotes !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes , Delete It !"
        }).then((result) => {
            if (result.isConfirmed) {
                Delete_Quotes(_id)
                Swal.fire({
                    title: "Deleted!",
                    text: "Quote has been deleted Sucessfully.",
                    icon: "success",
                    confirmButtonColor: "green",
                });
            }
        });

    }

    useEffect(() => {
        Get_Quotes()
        AOS.init();
    }, [])

    if (!loader) {
        return <LoderPageRound />
    }



    return (
        <div data-aos="fade-up">

            <div className='px-2'>
                <h4 style={{ color: "#0B618E" }}>Get Quotes </h4>
            </div>
            <div className='overflow-x-auto px-2' id='ScrollBarSetting'>
                <table className="mt-5 table table-striped" style={{ borderRadius: "10px", overflow: "hidden", boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 6px 2px" }}>
                    <thead className='thead-dark'>
                        <tr>
                            <th scope="col" className='text-left' style={{ color: "#0B618E", fontWeight: "normal" }}> ID </th>
                            <th scope="col" className='text-left' style={{ color: "#0B618E", fontWeight: "normal", textWrap: "nowrap" }}> First Name </th>
                            <th scope="col" className='text-left' style={{ color: "#0B618E", fontWeight: "normal", textWrap: "nowrap" }}> Last Name </th>
                            <th scope="col" className='text-center' style={{ color: "#0B618E", fontWeight: "normal" }}> Email </th>
                            <th scope="col" className='text-center' style={{ color: "#0B618E", fontWeight: "normal", textWrap: "nowrap" }}> Mobile No </th>
                            <th scope="col" className='text-center' style={{ color: "#0B618E", fontWeight: "normal" }}> Project Details </th>
                            <th scope="col" className='text-center' style={{ color: "#0B618E", fontWeight: "normal" }}> Action </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            GetQuotes.map((el, index) => {
                                return (
                                    <tr key={index}>
                                        <td className='text-left align-middle'>{index + 1}</td>
                                        <td className='text-center align-middle'> {el.FirstName}</td>
                                        <td className='text-center align-middle'> {el.LastName}</td>
                                        <td className='text-left align-middle'> {el.Email}</td>
                                        <td className='text-left align-middle'> {el.MobileNo}</td>
                                        <td style={{ textAlign: "justify" }}> {el.ProjectDescription}</td>
                                        <td className='text-center align-middle'>
                                            <RiDeleteBin5Line onClick={() => Delete_Confirmation(el._id)} className='pointer fs-5 me-3' style={{ color: "red" }} />
                                        </td>
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>
            </div>

        </div>
    );
}

export default GetQuotes;