import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import style from './getquote.module.css'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import axios from 'axios';
import Swal from 'sweetalert2';
import LoderPageRound from '../Loading/LoderPageRound';

const GetQuotes_Schema = Yup.object().shape({
    FirstName: Yup.string()
        .required('First name is required !')
        .min(2, 'First name must be at least 2 characters !')
        .max(50, 'First name cannot exceed 50 characters !'),
    LastName: Yup.string()
        .required('Last name is required !')
        .min(2, 'Last name must be at least 2 characters !')
        .max(50, 'Last name cannot exceed 50 characters !'),
    Email: Yup.string()
        .required('Email is required !')
        .email('Invalid email !'),
    MobileNo: Yup.string()
        .required('Mobile no is required !')
        .test('len', 'Mobile no must be 10 digits', (value) => {
            return value.toString().length === 10;
        })
        .matches(/^[0-9]{10}$/, 'Invalid mobile number format !'),
    ProjectDescription: Yup.string()
        .required('Project description is required !')
        .min(10, 'Project description must be at least 10 characters !')
        .max(3000, 'Project description cannot exceed 3000 characters !'),
});

const GetQuote = ({ showModal, closeModal }) => {
    const [rows, setRows] = useState(11)
    const [loader, Setloader] = useState(false);

    const adjustTextareaRows = () => {
        if (window.innerWidth > 989) {
            return 11;
        } else if (window.innerWidth > 768) {
            return 5;
        } else {
            return 3;
        }
    };

    let confirm_get_quotes = () => {
        Swal.fire({
            title: "Good job !",
            text: "You're Response is Submitted !",
            icon: "success",
            confirmButtonColor: '#0b618e',
            confirmButtonText: 'OK , Got it !',
        });
    }

    useEffect(() => {
        const handleResize = () => {
            const row = adjustTextareaRows();
            setRows(row);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (loader) {
        return <LoderPageRound />
    }


    return (
        <div>
            <Modal show={showModal} onHide={closeModal} centered >
                <Modal.Header className='pt-lg-5'>
                    <button className={`btn p-0 bg-white border-0 ${style.closebtn}`} onClick={closeModal}>
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.50041 8.03102L0.598557 2.12915C0.598556 2.12915 0.598555 2.12915 0.598554 2.12915C0.175483 1.70609 0.175482 1.02016 0.598552 0.597092C0.598554 0.59709 0.598555 0.597089 0.598557 0.597087M6.50041 8.03102L1.95384 0.773866C1.62841 0.448425 1.10076 0.448425 0.775331 0.773866L0.598557 0.597087M6.50041 8.03102L0.598572 13.9328L0.598555 13.9328C0.175504 14.356 0.175504 15.0418 0.598555 15.465L0.598576 15.465C1.02164 15.888 1.70757 15.888 2.13063 15.465L2.13063 15.465L8.03246 9.56308L13.9344 15.465C14.3574 15.888 15.0434 15.888 15.4664 15.465L15.4664 15.4649C15.8894 15.0418 15.8895 14.356 15.4664 13.9329L9.56452 8.03102L15.4664 2.12915L15.297 1.95978L15.4664 2.12915C15.8895 1.70608 15.8895 1.02016 15.4664 0.597096C15.0434 0.174015 14.3574 0.174015 13.9343 0.597096L8.03246 6.49897L2.13062 0.597092L6.50041 8.03102ZM0.598557 0.597087C1.02162 0.174017 1.70755 0.174018 2.13062 0.59709L0.598557 0.597087Z" fill="#0F0F0F" stroke="#0F0F0F" strokeWidth="0.5" />
                        </svg>
                    </button>
                    <div className='text-center w-100'>
                        <h1 className={style.modalheading}>Request a quote</h1>
                        <p className={style.modalcontent}>Connect with us for your requirements or queries,<br /> Well get back to you within 24 Hrs.</p>
                    </div>
                </Modal.Header>

                <Formik
                    initialValues={{
                        FirstName: '',
                        LastName: '',
                        Email: '',
                        MobileNo: '',
                        ProjectDescription: '',
                    }}
                    validationSchema={GetQuotes_Schema}
                    onSubmit={async (values, action) => {

                        Setloader(true)

                        axios.post("https://api.brainbinaryinfotech.com/getquote/add", values)
                            .then((res) => {
                                action.resetForm();
                                closeModal()
                                confirm_get_quotes()
                                Setloader(false)
                            })
                            .catch((err) => {
                                alert(err.response.data.message)
                                Setloader(false)
                            })

                    }}
                >
                    <Form>
                        <Modal.Body className='p-lg-4 p-2'>
                            <div className='d-flex justify-content-center'>
                                <div className={`w-100`}>
                                    <div className='d-lg-flex'>
                                        <div className={style.inputwidth}>
                                            <div className='m-lg-4 m-2'>
                                                <Field
                                                    type="text"
                                                    name="FirstName"
                                                    placeholder='First name'
                                                    className={`${style.inputfield} form-control`}
                                                />
                                                <ErrorMessage name="FirstName" component={'span'} className="text-danger" />
                                            </div>
                                            <div className='m-lg-4 m-2'>
                                                <Field
                                                    type="text"
                                                    name="LastName"
                                                    placeholder='Last name'
                                                    className={`${style.inputfield} form-control`}
                                                />
                                                <ErrorMessage name="LastName" component={'span'} className="text-danger" />

                                            </div>
                                            <div className='m-lg-4 m-2'>
                                                <Field
                                                    type="text"
                                                    name="Email"
                                                    placeholder='Email'
                                                    className={`${style.inputfield} form-control`}
                                                />
                                                <ErrorMessage name="Email" component={'span'} className="text-danger" />

                                            </div>
                                            <div className='m-lg-4 m-2'>
                                                <Field
                                                    type="text"
                                                    name="MobileNo"
                                                    placeholder='Mobile no'
                                                    className={`${style.inputfield} form-control`}
                                                    maxLength="10"
                                                />
                                                <ErrorMessage name="MobileNo" component={'span'} className="text-danger" />

                                            </div>
                                        </div>
                                        <div className={style.inputwidth}>
                                            <div className='m-lg-4 m-2'>
                                                <Field
                                                    as="textarea"
                                                    rows={rows}
                                                    name="ProjectDescription"
                                                    placeholder='Project description / Content'
                                                    className={`${style.inputfield} form-control`}
                                                    style={{ paddingBottom: "10px" }}
                                                />
                                                <ErrorMessage name="ProjectDescription" component={'span'} className="text-danger" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='border-0 pt-0 pb-lg-5 d-flex justify-content-center'>
                            <button type='submit' className={`btn ${style.sendbtn}`}>Send Message</button>
                        </Modal.Footer>
                    </Form>
                </Formik>

            </Modal>
        </div>
    )
}

export default GetQuote