import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { toast } from 'react-toastify';
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { FaRegSave } from "react-icons/fa";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import Swal from 'sweetalert2';
import LoderPageRound from '../Testing/LoderPageRound';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { GiSelfLove } from "react-icons/gi";
import { IoCheckmarkDoneSharp } from "react-icons/io5";


const CustomerLove_Schema = Yup.object().shape({
    image: Yup.mixed()
        .required('Plese add Image it is required !')
        .test('fileSize', 'File size is too large. Maximum allowed File size is 1MB.', (value) => {
            if (value && value instanceof File) {
                return value.size <= 1048576; // 1MB
            }
            return true;
        }),
    name: Yup.string()
        .required('Name is required !'),
    content: Yup.string()
        .required('Content is required !')
});


function OurCustomersLove() {

    const [show, setShow] = useState(false);
    const [CustomerLoveContent, setCustomerLoveContent] = React.useState([]);
    const [intialvalues, setintialvalues] = React.useState({ image: '', name: '', content: "" });
    const [Eid, setEid] = React.useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [loader, Setloader] = useState(false);
    const [TimeingLoader, setTimeingLoader] = useState(false);



    const handleShow = () => {
        setShow(true);
    }
    const handleClose = () => {
        setShow(false)
        setintialvalues({ image: '', name: '', content: "" })
        setImagePreview(null)
        setEid(null)
    }

    const Get_CustomerLoveContent = () => {
        axios.get("https://api.brainbinaryinfotech.com/ourcustomerslove/", {
            headers: { 'content-type': 'multipart/form-data' }
        })
            .then((res) => {
                setCustomerLoveContent(res.data.CUSTOMER_LOVE_DATA);
                Setloader(true)
            })
            .catch((err) => {
                console.log(err);
                Setloader(true)
            })
    }

    const Delete_Blog = (_id) => {
        setTimeingLoader(true)

        axios.delete("https://api.brainbinaryinfotech.com/ourcustomerslove/" + _id, {
            headers: { 'content-type': 'multipart/form-data' }
        })
            .then((res) => {
                // toast.success(res.data.message.toLowerCase())
                Get_CustomerLoveContent()
                setTimeingLoader(false)
            })
            .catch((err) => {
                console.log(err);
                setTimeingLoader(false)
            })
    }

    const Delete_Confirmation = (_id) => {
        Swal.fire({
            title: "Are you sure ?",
            text: "You won't to delete this Customer Love Us Content !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes , Delete It !"
        }).then((result) => {
            if (result.isConfirmed) {
                Delete_Blog(_id)
                setTimeout(() => {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Customer Love Us Content has been deleted Sucessfully.",
                        icon: "success",
                        confirmButtonColor: "green",
                    });
                }, 1400);
            }
        });

    }


    const Edit_Blog = (value, _id) => {


        setintialvalues({
            image: value.image,
            name: value.name,
            content: value.content,
            status: value.status
        })
        setEid(_id)
        handleShow()
        setImagePreview(value.image.url);
    }

    useEffect(() => {
        Get_CustomerLoveContent()
        AOS.init();
    }, [])


    if (!loader || TimeingLoader) {
        return <LoderPageRound />
    }


    return (
        <div data-aos="fade-up">
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className="d-flex">
                            <GiSelfLove className='fs-5 me-2' />
                            <h6> {Eid ? 'Update' : 'Add'} Customer Love Us Content </h6>
                        </div>
                    </Modal.Title>
                </Modal.Header>

                <Formik
                    initialValues={intialvalues}
                    enableReinitialize
                    validationSchema={CustomerLove_Schema}
                    onSubmit={async (values, action) => {

                        let formData = new FormData();

                        formData.append('image', values.image);
                        formData.append('name', values.name);
                        formData.append('content', values.content);


                        if (Eid) {
                            setTimeingLoader(true)
                            axios.put("https://api.brainbinaryinfotech.com/ourcustomerslove/" + Eid, values, {
                                headers: { 'content-type': 'multipart/form-data' }
                            })
                                .then((res) => {
                                    toast.success(res.data.message.toLowerCase())
                                    Get_CustomerLoveContent()
                                    handleClose()
                                    setEid(null)
                                    action.resetForm()
                                    setImagePreview(null)
                                    setTimeingLoader(false)

                                })
                                .catch((err) => {
                                    console.log(err);
                                    setTimeingLoader(false)
                                })
                        }
                        else {
                            setTimeingLoader(true)
                            axios.post("https://api.brainbinaryinfotech.com/ourcustomerslove/add", values, {
                                headers: { 'content-type': 'multipart/form-data' }
                            })
                                .then((res) => {
                                    toast.success(res.data.message.toLowerCase())
                                    Get_CustomerLoveContent()
                                    handleClose()
                                    action.resetForm()
                                    setImagePreview(null)
                                    setTimeingLoader(false)

                                })
                                .catch((err) => {
                                    toast.error(err.response.data.message.toLowerCase())
                                    console.log(err);
                                    setTimeingLoader(false)
                                })
                        }

                        setintialvalues({ image: '', name: '', content: "" })
                        action.resetForm()

                    }}
                >
                    {
                        (props) => (
                            <Form>
                                <Modal.Body>

                                    <label htmlFor="Image" className='mb-2'> Image </label>
                                    <div className='mb-3'>
                                        <input onChange={(e) => {
                                            props.setFieldValue("image", e.target.files[0]);
                                            setImagePreview(URL.createObjectURL(e.target.files[0]));
                                        }} type="file" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                    </div>

                                    {imagePreview && (
                                        <div className="mt-3">
                                            <img src={imagePreview} alt="ERROR 404" className='mb-2' style={{ width: '80px', height: '80px', borderRadius: "50%" }} />
                                        </div>
                                    )}
                                    <span> <ErrorMessage name='image' component={'p'} className='text-danger' /> </span>


                                    <label htmlFor="Heading" className='mb-2'> Name </label>
                                    <div className="input-group mb-2">
                                        <Field name='name' type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                    </div>
                                    <span> <ErrorMessage name='name' component={'p'} className='text-danger' /> </span>


                                    <label htmlFor="Name" className='mb-2'> Content </label>
                                    <div className="input-group mb-2">
                                        <Field name='content' type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                    </div>
                                    <span className='mb-2'> <ErrorMessage name='content' component={'p'} className='text-danger' /> </span>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button style={{ backgroundColor: "#0B618E" }} className="border-0" type='submit'>
                                        {
                                            Eid ? <>  <FaRegSave className="fs-5 me-1" /> Save </> : <> <IoCheckmarkDoneSharp className="fs-5 me-1" /> Submit </>
                                        }
                                    </Button>
                                    <Button style={{ backgroundColor: "#0B618E" }} className="border-0" onClick={() => handleClose()}>  <MdOutlineCancel className="fs-5" /> Cancel</Button>
                                </Modal.Footer>
                            </Form>
                        )
                    }
                </Formik>
            </Modal>

            <div className='d-flex justify-content-between align-items-center px-2'>
                <div className='d-flex'>
                    <h4 style={{ color: "#0B618E" }}> Our Customers Love Us ( Slider ) </h4>
                </div>
                <Button variant="primary" className='border-0 d-flex align-items-center' style={{ backgroundColor: "#0B618E" }} onClick={handleShow}>
                    Add Customers Love Us Content <GiSelfLove className='fs-3 ms-2 p-1' />
                </Button>
            </div>
            <div className='overflow-x-auto px-2' id='ScrollBarSetting'>

                <table className="mt-5 table table-striped" style={{ borderRadius: "10px", overflow: "hidden", boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 6px 2px" }}>
                    <thead className='thead-dark'>
                        <tr>
                            <th scope="col" className='text-center' style={{ color: "#0B618E", fontWeight: "normal" }}> ID </th>
                            <th scope="col" className='text-center' style={{ color: "#0B618E", fontWeight: "normal", textWrap: "nowrap" }}> Customer Image </th>
                            <th scope="col" className='text-left' style={{ color: "#0B618E", fontWeight: "normal" }}> Name </th>
                            <th scope="col" className='text-left' style={{ color: "#0B618E", fontWeight: "normal" }}> Content </th>
                            <th scope="col" className='text-center' style={{ color: "#0B618E", fontWeight: "normal" }}> Actions </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            CustomerLoveContent.map((el, index) => {
                                return (
                                    <tr key={index}>
                                        <td className='text-center align-middle'>{index + 1}</td>
                                        <td className='text-center align-middle'>
                                            <img src={el.image.url} alt="ERROR 404 IMG NOT FOUND" style={{ height: "70px", width: "70px", borderRadius: "50%" }} />
                                        </td>
                                        <td className='text-left align-middle' style={{ textWrap: "nowrap" }}> {el.name}</td>
                                        <td className='text-left align-middle' style={{ textAlign: "justify" }}> {el.content}</td>
                                        <td className='text-center align-middle' style={{ textWrap: "nowrap" }}>
                                            <RiDeleteBin5Line onClick={() => Delete_Confirmation(el._id)} className='pointer fs-5 me-3' style={{ color: "red" }} />
                                            <FaRegEdit onClick={() => Edit_Blog(el, el._id)} className='pointer fs-5 me-3' style={{ color: "green" }} />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

        </div >
    );
}

export default OurCustomersLove;