import React from 'react';
import style from './ourservice.module.css';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import { useEffect } from 'react';


const OurService = () => {
  const [OurService, setOurService] = React.useState([]);

  const Get_OurService = () => {
    axios.get("https://api.brainbinaryinfotech.com/ourservice/", {
      headers: { 'content-type': 'multipart/form-data' }
    })
      .then((res) => {
        setOurService(res.data.OUR_SERVICE_DATA);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    Get_OurService()
  }, [])

  return (
    <div className={style.maindiv} >
      <div data-aos="zoom-in" data-aos-animation="2000">
        <div className='text-center text-secondary mb-1'>
          Want to build app?
        </div>
        <div className={`heading headingborder text-center mb-3 pb-3`}>
          It's not magic, It's our service
        </div>
        <div className='text-center text-secondary mb-md-4 mb-2 px-4 '>
          MicraSol crafts Custom software and Innovative solutions to drive business growth - See How MicraSol's service can help you achieve your goal.
        </div>
      </div>
      <div className="row">
        {
          OurService.map((el, index) => {
            return (
              <div className={`col-12 col-md-12 col-lg-6 col-xxl-4 g-xxl-5 g-xl-5 g-lg-5 g-md-4 g-4`} key={index} data-aos="fade-up">
                <Card className='border-0'>
                  <Card.Img className={`${style.CardImg}`} src={el.image.url} alt='ERROR 404' />
                  <Card.Body className='p-0 py-3'>
                    <Card.Title>{el.title}</Card.Title>
                    <Card.Text className='text-secondary' style={{ textAlign: "justify" }}>
                      {el.content}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            )
          })
        }
      </div>
    </div >
  )
}

export default OurService;
