import React from 'react';
import style from './haveanidea.module.css';
import { Link } from "react-router-dom";

function Haveanidea() {
    return (
        <div className={style.maindiv}>
            <div className={style.bgdiv} data-aos="zoom-in">
                <div className='mt-3'>
                    <p className='font-semibold text-lg' style={{ fontSize: "18px" }}> Have an idea ? </p>
                </div>
                <div className='mt-3'>
                    <h1 className='fw-bold px-3' style={{ fontSize: "46px" }}>
                        Build Better. Faster. Together.
                    </h1>
                </div>
                <div className={`${style.setelement} mt-3`}>
                    <p className='mb-0' style={{ fontSize: "18px" }}> Transform your Innovative ideas into digital products and make people's lives better ! </p>
                </div>
                <div className='mt-5'>
                    <Link to="/contact-us">
                        <button type="button" className={`${style.DiscussBtn} rounded-pill px-4 py-2`}>
                            Let's discuss
                        </button>
                    </Link>
                </div>
            </div>
        </div>

    )
}

export default Haveanidea;